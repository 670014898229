(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/supportern/assets/javascript/utils/payment.js') >= 0) return;  svs.modules.push('/modules/supportern/assets/javascript/utils/payment.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

setGlobal('svs.supportern.utils.payment', {
  cleanup: drawId => {
    const currentStorage = svs.components.Storage.browser.get('ticketcount', 'supportern');
    const _Object$assign = Object.assign({}, currentStorage),
      {
        [drawId]: _
      } = _Object$assign,
      newStorage = _objectWithoutProperties(_Object$assign, [drawId].map(_toPropertyKey)); 
    svs.components.Storage.browser.set('ticketcount', 'supportern', newStorage, svs.components.Storage.constants.EXPIRES_10M);
  },
  updateDraws: _ref => {
    let {
      draws,
      id,
      results
    } = _ref;
    return draws.map(d => _objectSpread(_objectSpread({}, d), {}, {
      results: d.id === id ? results : d.results
    }));
  },
  showSuccessDialog: _ref2 => {
    let {
      teamName
    } = _ref2;
    const {
      onOverlayOK,
      onOverlayNavigate
    } = svs.supportern.utils.tracking;
    const dialog = new svs.ui.dialog.Confirm({
      icon: 'thumbs-up',
      branding: svs.ui.dialog.branding.TUR,
      type: svs.ui.dialog.type.INFO,
      title: 'Dina spel är betalda',
      message: [{
        type: svs.ui.dialog.message.TEXT,
        text: "Du hittar spelen under Mina Supporternspel.",
        align: svs.ui.dialog.message.align.CENTER
      }, {
        type: svs.ui.dialog.message.TEXT,
        text: "<b>".concat(teamName, "</b> tackar f\xF6r ditt st\xF6d och \xF6nskar lycka till!"),
        align: svs.ui.dialog.message.align.CENTER
      }],
      actions: [{
        type: svs.components.dialog.button.SECONDARY,
        callback() {
          onOverlayOK();
          dialog.close();
        },
        title: 'Stäng'
      }, {
        type: svs.components.dialog.button.PRIMARY,
        callback() {
          onOverlayNavigate();
          window.location.href = svs.core.urlMapping.get('supportern_OngoingBets');
          dialog.close();
        },
        title: 'Visa mina spel'
      }]
    });
    svs.ui.dialog.api.add(dialog);
  }
});

 })(window);